/* ボディ全体のスタイリング */
body {
  margin: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #282c34; /* ダークテーマの背景色 */
  color: white; /* テキストの色 */
  text-align: center; /* 中央寄せ */
}

/* アプリコンテナのスタイリング */
.App {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* ビューポートの高さを全体に */
}

/* 時刻表示のスタイリング */
h1 {
  font-size: 3rem; /* フォントサイズの拡大 */
  margin: 0;
}

/* 時刻を表示する部分に適用するアニメーション */
@keyframes fade {
  from { opacity: 0; }
  to { opacity: 1; }
}

h1 {
  animation: fade 2s ease-in-out infinite alternate; /* アニメーションの適用 */
}

/* アプリコンテナのスタイリング */
.App {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* ビューポートの高さを全体に */
  flex-direction: column; /* 子要素を縦に並べる */
}

/* 時刻表示のコンテナ */
.time-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%; /* コンテナの幅を固定する */
  margin: 0 auto; /* 中央寄せ */
  padding: 20px;
  box-sizing: border-box; /* パディングを幅に含める */
}

/* 時刻、分、秒を表示する各ブロックのスタイリング */
.time-block {
  flex: 1; /* 同じ割合でスペースを取る */
  text-align: center;
}

/* 時刻表示の全体のコンテナ */
.time-display {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; /* 高さを固定 */
  margin: 20px; /* 周囲のマージン */
}

/* 時間、分、秒を表示する各ボックス */
.time-unit {
  margin: 0 10px; /* 周囲のマージンを設定 */
  padding: 10px; /* 内側のパディング */
  min-width: 50px; /* 最小幅を設定 */
  text-align: center; /* テキストを中央寄せ */
  background: #333; /* 背景色 */
  color: #fff; /* テキスト色 */
  border-radius: 5px; /* 角丸の設定 */
}
